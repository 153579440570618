<template>
  <div
    class="modal"
    v-if="value"
    :style="
      value ? 'display:block;background-color:#3333337a;' : 'display:none;'
    "
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <div class="modal-header d-flex align-items-center ">
          <div
            class="w-100 d-flex flex-wrap align-items-center justify-content-between"
          >
            <div>
              <h3 class="mb-0 d-flex align-items-center">
                <div
                  class="icon-title d-flex align-items-center justify-content-center"
                >
                  <icon width="30" height="30" name="user-friends"></icon>
                </div>
                <div>
                  <div class="">
                    Contactez-nous
                  </div>
                  <!-- grand écran -->
                  <div class="fs-6 fw-light d-none d-md-block">
                    Les champs avec une astérisque sont obligatoires
                  </div>
                  <!-- petit écran -->
                  <div class="fs-6 fw-light d-md-none">
                    * champs obligatoires
                  </div>
                </div>
              </h3>
            </div>
          </div>
        </div>
        <div class="modal-body bg-white">
          <div class="container">
            <div class="row mt-1">
              <div class="col-md-8 mx-auto">
                <div>
                  <h1>Contacts</h1>
                  <hr />
                  <h4 class="text-red">
                    Interprofession des Vins du Sud-Ouest France
                  </h4>
                  <p>
                    Centre INRA - Chemin de Borde Rouge <br />
                    CS52637 - 31321 Castanet Tolosan<br />
                    Tél. : 05 61 73 87 06<br />
                    Fax : 05 61 75 64 39
                  </p>
                </div>
                <form id="form-contact">
                  <div class="row">
                    <div class="col-md-6 mt-2">
                      <m-form-text
                        label="Nom *"
                        class=""
                        :name="$Utils.randomstring('name')"
                        v-model="message.name"
                      ></m-form-text>
                    </div>
                    <div class="col-md-6 mt-2">
                      <m-form-text
                        label="Prénom *"
                        class=""
                        :name="$Utils.randomstring('firstname')"
                        v-model="message.firstname"
                      ></m-form-text>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6 mt-2">
                      <m-form-text
                        label="Téléphone"
                        type="phone"
                        class=""
                        :name="$Utils.randomstring('phone')"
                        v-model="message.phone"
                      ></m-form-text>
                    </div>
                    <div class="col-md-6 mt-2">
                      <m-form-text
                        label="Email *"
                        class=""
                        :name="$Utils.randomstring('email')"
                        v-model="message.email"
                      ></m-form-text>
                    </div>
                  </div>
                  <m-form-text
                    label="Votre message *"
                    class="mt-2"
                    type="textarea"
                    :name="$Utils.randomstring('message')"
                    v-model="message.message"
                  ></m-form-text>
                  <m-form-checkbox
                    class="mt-2"
                    label="Consentement"
                    :name="$Utils.randomstring('consentement')"
                    v-model="message.consentement"
                  ></m-form-checkbox>

                  <!--  <vue-recaptcha
                    ref="invisibleRecaptcha"
                    @verify="onVerify"
                    @expired="onExpired"
                    :sitekey="google_key"
                    :loadRecaptchaScript="true"
                    theme="light"
                  ></vue-recaptcha> -->
                </form>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer d-flex flex-row">
          <div class="ms-auto"></div>
          <button type="button" class="btn btn-secondary " @click="cancelWin">
            Annuler
          </button>
          <button
            type="button"
            class="btn btn-primary ms-2"
            @click="sendMessage"
          >
            Envoyer
          </button>
        </div>
      </div>
    </div>
    <m-message-dialog
      v-model="dialogErr"
      title="Erreur"
      :text="dialogErrTxt"
    ></m-message-dialog>
  </div>
</template>

<script>
export default {
  name: "ContactWin",
  components: {},
  props: {
    value: { default: false, type: Boolean },
    row_pa: { type: Object }
  },
  data() {
    return {
      message: {
        name: "",
        firstname: "",
        phone: "",
        email: "",
        message: "",
        consentement: false
      },
      recaptcha: false,
      google_key: this.$config.google_key,
      dialogErr: false,
      dialogErrTxt: ""
    };
  },
  watch: {
    value(v) {
      if (v) {
        this.message.name = this.row_pa.pa_name;
        this.message.firstname = this.row_pa.pa_firstname;
        this.message.phone = this.row_pa.pa_phone_fix;
        if (this.row_pa.login) this.message.email = this.row_pa.login.lo_login;
      }
    }
  },
  computed: {},
  created() {},
  mounted() {
    window.addEventListener("keyup", this.onKeyup);
  },
  destroyed() {
    window.removeEventListener("keyup", this.onKeyup);
  },
  methods: {
    onKeyup(e) {
      if (e.key === "Escape") {
        this.cancelWin();
      }
    },
    /*     onVerify: function(response) {
      this.recaptcha = true;
      // console.log("Verify: " + response);
    },
    onExpired: function() {
      //console.log("Expired");
      this.recaptcha = false;
    },
    resetRecaptcha() {
      this.$refs.recaptcha.reset(); // Direct call reset method
    }, */
    async sendMessage() {
      let err = [];
      let fieldRequired = [
        { field: "name", text: "Nom" },
        { field: "firstname", text: "Prénom" },
        { field: "email", text: "Email" },
        { field: "message", text: "Message" }
      ];
      for (let ifi = 0; ifi < fieldRequired.length; ifi++) {
        const field = fieldRequired[ifi];
        if (!this.message[field.field]) err.push(field);
      }
      /*       if (!this.recaptcha) {
        err.push({
          field: "recaptcha",
          text: "Je ne suis pas un robot"
        });
      } */

      if (err.length) {
        this.dialogErrTxt =
          "<span class='fw-bold'>Les champs suivants sont obligatoires : </span><br>";
        for (let ierr = 0; ierr < err.length; ierr++) {
          const error = err[ierr];
          this.dialogErrTxt += error.text + " <br>";
        }
        this.dialogErr = true;
        return;
      } else {
        let response = await this.$axios.post(
          this.$config.server_url + "/candidats/1.0/contactus",
          this.message
        );
        if (response.data.data.success) {
          this.$store.dispatch("showToast", {
            title: "Envoi OK ",
            text: "Votre message a bien été envoyée",
            color: "green"
          });
          this.resetNewContact();
          this.$emit("input", false);
        } else {
          this.$store.dispatch("showToast", {
            title: "Erreur",
            text: response.data.data.error,
            color: "red"
          });
        }
      }
    },
    resetNewContact() {
      this.message = {
        name: "",
        firstname: "",
        phone: "",
        email: "",
        message: "",
        consentement: false
      };
      // this.resetRecaptcha();
    },
    cancelWin() {
      this.$emit("input", false);
    }
  }
};
</script>

<style scoped lang="scss"></style>
