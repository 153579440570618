<template>
  <div
    class="modal"
    v-if="value"
    :style="
      value ? 'display:block;background-color:#3333337a;' : 'display:none;'
    "
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <div class="modal-header d-flex align-items-center ">
          <div>
            <h3 class="mb-0 d-flex align-items-center">
              <div
                class="icon-title d-flex align-items-center justify-content-center"
              >
                <icon width="30" height="30" name="euro-sign"></icon>
              </div>
              <div>
                <div>
                  Validation et paiement de votre candidature
                </div>
                <div class="fs-6 fw-light"></div>
              </div>
            </h3>
          </div>
        </div>
        <div class="modal-body">
          <div class="">
            <div class="row ">
              <div class="col-md-6">
                <h5>Adresse de facturation</h5>
                <div>{{ row_pa.pa_society_fac }}</div>
                <div
                  v-html="
                    $options.filters.formatAddress(
                      row_pa,
                      'pa_',
                      false,
                      false,
                      '_fac'
                    )
                  "
                ></div>
                <div>
                  <a :href="row_pa.pa_web" target="bank">{{ row_pa.pa_web }}</a>
                </div>
              </div>
              <div class="col-md-6">
                <h5>Contact</h5>
                <div>
                  {{
                    $options.filters.formatContactNameSimple(
                      row_pa,
                      "pa_",
                      true
                    )
                  }}
                </div>
                <div>Fonction : {{ row_pa.pa_fonction }}</div>
                <div>Tèl fixe : {{ row_pa.pa_phone_fix }}</div>
                <div>Portable : {{ row_pa.pa_phone_mobile }}</div>
                <div>Email : {{ row_pa.login.lo_login }}</div>
              </div>
            </div>
          </div>
          <div class=" mt-3">
            <h5>Vos vins :</h5>
            <!-- grand écran -->
            <m-list-simple
              tableClass="table-hover table-striped d-none d-md-table"
              style="overflow: visible;"
              ref="wineslist"
              :items="wines"
              item-value="wi_id"
              :total="wines.length"
            >
              <template v-slot:ths="{}">
                <th><span>Nom</span></th>
                <th><span>Dénomination</span></th>
                <th><span>Couleur</span></th>
                <th><span>Millésime</span></th>
              </template>
              <template v-slot:tds="{ item }">
                <td class="d-flex align-items-center ">
                  {{ item | formatWineName }}
                </td>
                <td>
                  {{
                    item.denomination
                      ? item.denomination.de_name
                      : item.wi_denomination
                  }}
                </td>
                <td>{{ item.wi_couleur | formatWineColor }}</td>
                <td>{{ item.wi_millesime | formatWineMillesime }}</td>
              </template>
            </m-list-simple>
            <!-- petit écran -->
            <m-list-simple
              tableClass="table-hover table-striped d-md-none"
              style="overflow: visible;"
              ref="wineslist"
              :items="wines"
              item-value="wi_id"
              :total="wines.length"
            >
              <template v-slot:ths="{}"></template>
              <template v-slot:tds="{ item }">
                <td class=" ">
                  <div>{{ item | formatWineName }}</div>
                  <div>
                    {{
                      item.denomination
                        ? item.denomination.de_name
                        : item.wi_denomination
                    }}
                    - {{ item.wi_couleur | formatWineColor }} -
                    {{ item.wi_millesime | formatWineMillesime }}
                  </div>
                </td>
              </template>
            </m-list-simple>
            <!--  -->
          </div>
          <div class="d-md-flex justify-content-between mt-3">
            <!-- <div>
              <button @click="cancelWin" class="btn btn-primary ">
                Modifier
              </button>
            </div> -->
            <div class="mt-2 me-4">
              <div class="row">
                <div class="col-6">Total HT :</div>
                <div class="col-6">
                  {{ totalHT | formatPriceDivisePar100 }}
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  TVA({{ row_pa.yp_echantillons_tva }}%) :
                </div>
                <div class="col-6">
                  {{ tva | formatPriceDivisePar100 }}
                </div>
              </div>
              <div class="mt-2">
                <h4>
                  À payer :
                  {{ totalTTC | formatPriceDivisePar100 }}
                  TTC
                </h4>
              </div>
            </div>
          </div>
          <div class="d-md-flex mt-4">
            <div
              class="d-flex flex-fill align-items-center frame-paiement mx-1 my-1  "
            >
              <div>
                <img
                  class="img-paiement"
                  src="/images/icons/card.png"
                  alt="icon carte bancaire"
                />
              </div>
              <div class="d-flex flex-grow-1 ">
                <form method="POST" :action="serverpaybox" name="myForm">
                  <div v-html="formcb"></div>
                  <button class="btn btn-lg btn-primary  ms-3 btn-sizing">
                    Paiement par carte bancaire
                  </button>
                </form>
              </div>
            </div>
            <div
              class="d-flex flex-fill align-items-center frame-paiement mx-1 my-1 "
            >
              <div>
                <img
                  class="img-paiement"
                  src="/images/icons/cheque.png"
                  alt="icon carte bancaire"
                />
              </div>
              <div class="d-flex flex-grow-1 ">
                <button
                  @click="createInvoiceConfirm('Chèque')"
                  class="btn btn-lg btn-primary ms-3 btn-sizing"
                >
                  Paiement par chèque
                </button>
              </div>
            </div>
            <div
              class="d-flex  flex-fill align-items-center frame-paiement mx-1 my-1 "
            >
              <div>
                <img
                  class="img-paiement"
                  src="/images/icons/virement.png"
                  alt="icon carte bancaire"
                />
              </div>
              <div class="d-flex flex-grow-1">
                <button
                  @click="createInvoiceConfirm('Virement')"
                  class="btn btn-lg btn-primary ms-3 btn-sizing"
                >
                  Paiement par virement
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer d-flex flex-row">
          <div class="ms-auto"></div>
          <button type="button" class="btn btn-secondary " @click="cancelWin">
            Annuler
          </button>
        </div>
      </div>
    </div>
    <m-message-dialog
      v-model="dialogErr"
      title="Erreur"
      :text="dialogErrTxt"
    ></m-message-dialog>
    <m-confirm-dialog
      v-model="confirmFacture"
      :text="confirmFactureTxt"
      title="Confirmation"
      @canceled="confirmFacture = false"
      @confirmed="createInvoice"
    ></m-confirm-dialog>
  </div>
</template>

<script>
export default {
  name: "PayerWinEdit",
  components: {},
  props: {
    pa_id: Number,
    in_id: Number,
    value: { default: false, type: Boolean }
  },
  data() {
    return {
      formcb: "",
      serverpaybox: "",
      dialogErrTxt: "",
      dialogErr: false,
      wines: [],
      row_pa: { login: {} },
      row_in: {},
      totalHT: 0,
      tva: 0,
      totalTTC: 0,
      confirmFactureTxt: "",
      confirmFacture: false,
      paymentMethod: ""
    };
  },
  watch: {
    async value(v) {
      if (v) {
        this.wines = [];
        if (this.in_id) await this.loadInvoice();
        else await this.loadParticipation();
        await this.downloadFormCb();
      }
    }
  },
  computed: {},
  created() {},
  mounted() {
    // this.downloadFormCb();
    window.addEventListener("keyup", this.onKeyup);
  },
  destroyed() {
    window.removeEventListener("keyup", this.onKeyup);
  },
  methods: {
    onKeyup(e) {
      if (e.key === "Escape") {
        this.cancelWin();
      }
    },
    async downloadFormCb() {
      let response = await this.$axios.post(
        // this.$config.server_url + "/candidats/1.0/participations/" + this.pa_id,
        this.$config.server_url + "/candidats/1.0/paybox/form",
        { in_id: this.row_in.in_id, pa_id: this.row_pa.pa_id }
      );
      // console.log("downloadFormCb", response.data.data.form);
      this.formcb = response.data.data.form;
      this.serverpaybox = response.data.data.serverpaybox;
    },
    async loadInvoice() {
      let params = {};
      let response = await this.$axios.get(
        // this.$config.server_url + "/candidats/1.0/participations/" + this.pa_id,
        this.$config.server_url + "/candidats/1.0/invoices/" + this.in_id,
        { params }
      );
      // console.log("response.data.data", response.data.data);

      this.row_in = response.data.data;
      this.row_pa = this.row_in.participation;
      // console.log("this.row_pa", this.pa_id, this.row_pa);
      this.wines = this.row_in.wines;
      this.totalHT = this.row_in.in_price_ht;
      this.tva = this.row_in.in_price_tva;
      this.totalTTC = this.row_in.in_price_ttc;
    },
    async loadParticipation() {
      let params = {};
      let response = await this.$axios.get(
        // this.$config.server_url + "/candidats/1.0/participations/" + this.pa_id,
        this.$config.server_url +
          "/candidats/1.0/participations/" +
          this.$store.state.year +
          "/fromyear",
        { params }
      );

      this.row_pa = response.data.data;
      // console.log("this.row_pa", this.pa_id, this.row_pa);
      this.wines = this.row_pa.winesCreate;
      this.totalHT =
        this.wines.length * this.row_pa.yearpreferences.yp_echantillons_price;
      this.tva =
        this.wines.length *
        this.row_pa.yearpreferences.yp_echantillons_price *
        (this.row_pa.yearpreferences.yp_echantillons_tva / 100);
      this.totalTTC =
        this.wines.length * this.row_pa.yearpreferences.yp_echantillons_price +
        this.wines.length *
          this.row_pa.yearpreferences.yp_echantillons_price *
          (this.row_pa.yearpreferences.yp_echantillons_tva / 100);
    },
    createInvoiceConfirm(paiement) {
      this.paymentMethod = paiement;
      if (this.paymentMethod == "CB") {
        this.createInvoice();
      } else {
        this.confirmFacture = true;
        this.confirmFactureTxt = `Vous souhaitez payer par ${this.paymentMethod}. Votre vacture vous a été envoyée par mail.`;
        if (this.paymentMethod === "Virement")
          this.confirmFactureTxt = this.$store.state.userparticipation.yearpreferences.yp_infos_payment_virement;
        if (this.paymentMethod === "Chèque")
          this.confirmFactureTxt = this.$store.state.userparticipation.yearpreferences.yp_infos_payment_cheque;
      }
    },
    async createInvoice() {
      let data = {
        in_typepaiement: this.paymentMethod,
        pa_id: this.row_pa.pa_id
      };
      let response;
      if (this.in_id) {
        response = await this.$axios.put(
          this.$config.server_url + "/candidats/1.0/invoices/" + this.in_id,
          data
        );
      } else {
        response = await this.$axios.post(
          this.$config.server_url + "/candidats/1.0/invoices",
          data
        );
      }
      if (response.data.err) {
        this.$store.dispatch("showDialogError", response.data.err.message);
        return;
      }

      // if (this.paymentMethod === "CB") {
      //   let response2 = await this.$axios.post(
      //     this.$config.server_url +
      //       "/commons/1.0/webhook/" +
      //       response.data.data.in_id
      //   );
      //   if (response2.data.data.success)
      //     return window.open("/home/cb/ok", "_self");
      //   else return window.open("/home/cb/ko", "_self");
      // }

      // this.paymentMethod = "";
      this.confirmFacture = false;
      this.confirmFactureTxt = "";
      this.$emit("input", false);
      this.$emit("WinEditActions", { action: "saved" });
      //this.loadCandidat();
    },

    cancelWin() {
      this.$emit("WinEditActions", { action: "canceled" });
    }
  }
};
</script>

<style scoped lang="scss">
.modal-content {
  background-color: #fff;
}
.frame-paiement {
  padding: 10px;
  border: 1px solid gray;
  border-radius: 15px;
  .img-paiement {
    width: 40px;
  }
}
@media (min-width: 992px) {
  .frame-paiement {
    padding: 20px;
    border-radius: 20px;
    .img-paiement {
      width: 50px;
    }
  }
}
</style>
