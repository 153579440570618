<template>
  <div :class="$store.state.theme">
    <!-- @click="myaccountDropdown = false" -->
    <div v-if="$store.state.connected" class="">
      <div v-if="menuRoute !== 'print'" class="top-bar d-flex">
        <div
          class="top-bar-left p-0 text-center container-logo-app"
          :style="
            `background-image:url(${this.$config.server_url}/commons/1.0/files/yp_file_logo/${year}); min-width:130px;`
          "
        ></div>
        <div
          class="top-bar-right d-flex align-items-center justify-content-between relative"
        >
          <div class="d-flex align-items-center">
            <div
              @click.stop="hideOrShowMenu"
              class="d-flex align-items-center p-2"
            >
              <icon class="icon-topbar" name="bars"></icon>
            </div>
          </div>
          <div class="nav-left d-flex">
            <div
              class="pointer me-3 align-items-center d-none d-md-flex"
              @click="returnWeb"
            >
              <icon
                width="22"
                height="22"
                name="arrow-circle-left"
                class="me-2 icon-topbar"
              ></icon>
              <div class="icon-topbar">Retour au site</div>
            </div>
            <!-- <div
              class="pointer me-3 d-flex align-items-center"
              @click="downloadHelp"
            >
              <icon
                width="22"
                height="22"
                name="question-circle"
                class="me-2 icon-topbar"
              ></icon>
              <div class="icon-topbar">Aide</div>
            </div> -->
            <div class="separation-verticale"></div>
            <div class="dropdown">
              <div
                @click.stop="myaccountDropdown = !myaccountDropdown"
                aria-expanded="false"
                class="pointer ms-3 me-4 justify-content-center"
              >
                <div class="d-flex">
                  <div class="ps-2 pt-1" style="line-height:1rem;">
                    <span style="color:#FB5958; font-weight:bold;">
                      {{ $store.state.usercandidat.lo_firstname }}
                      {{ $store.state.usercandidat.lo_name }}</span
                    >
                    <br />
                    <span class="small-text"></span>
                  </div>
                </div>
                <!--<img
                  class="container-avatar "
                  :src="
                    `background-image: url(${this.$config.server_url}/backoffice/1.0/users/${this.$store.state.user.us_id}/avatar?token=${this.$store.state.accesstoken})`
                  "
                  alt="avatar utilisateur"
                /> -->
              </div>
              <!-- <transition name="slide-myaccount"> -->
              <div class="dropdown-menu menu-profil" v-if="myaccountDropdown">
                <div class="py-1 px-4 text-nowrap">
                  {{
                    $options.filters.formatContactNameSimple(
                      $store.state.usercandidat,
                      "lo_"
                    )
                  }}<br />
                  <span class="small-text">{{ getIsCandidatJure() }}</span>
                </div>
                <hr class="dropdown-divider" />
                <!--  <a class="dropdown-item pointer" @click="userWinEdit"
                  >Ma fiche</a
                >
                <a class="dropdown-item pointer" @click="preferenceWinEdit"
                  >Mes préférences</a
                > 
                <hr class="dropdown-divider" />-->
                <a class="dropdown-item pointer" @click="logout"
                  >Me déconnecter</a
                >
              </div>
              <!-- </transition> -->
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex">
        <transition name="slide-menu">
          <div v-if="menuRoute !== 'print' && showMenu" class="menu" ref="menu">
            <h4 class="color-white ms-2 mt-2 mb-3">
              Prochaines <br /><span class="fw-bold">échéances</span>
            </h4>
            <ul>
              <li>
                <div class="color-white">
                  Date limite inscription candidats :
                </div>
                <div class="color-blue">
                  {{
                    $store.state.userparticipation.yearpreferences
                      .yp_end_inscription_date_candidate | formatDateText
                  }}
                </div>
              </li>
              <li>
                <div class="color-white">
                  Date limite inscription jurés :
                </div>
                <div class="color-blue">
                  {{
                    $store.state.userparticipation.yearpreferences
                      .yp_end_inscription_date_jure | formatDateText
                  }}
                </div>
              </li>
              <li>
                <div class="color-white">
                  Date limite d'envoi des échantillons :
                </div>
                <div class="color-blue">
                  {{
                    $store.state.userparticipation.yearpreferences
                      .yp_end_send_echantillons_date | formatDateText
                  }}
                </div>
              </li>
              <li>
                <div class="color-white">
                  Date du concours :
                </div>
                <div class="color-blue">
                  {{
                    $store.state.userparticipation.yearpreferences
                      .yp_start_concours_date | formatDateText
                  }}
                </div>
              </li>
              <li>
                <div class="color-white">
                  Date résultats :
                </div>
                <div class="color-blue">
                  {{
                    $store.state.userparticipation.yearpreferences
                      .yp_results_date | formatDateText
                  }}
                </div>
              </li>
            </ul>
            <div
              v-if="
                $store.state.userparticipation &&
                  $store.state.userparticipation.pa_candidat
              "
            >
              <hr class="color-white" />
              <div
                class="color-white text-center pointer px-3"
                @click="downloadCompletFiles"
              >
                <img
                  class="img-fluid icon-menu"
                  src="/images/icons/mailingliste.png"
                  alt=""
                />
                <div>
                  Télécharger le dossier complet
                </div>
              </div>
              <!-- <div
                @click="printMacaronFiles"
                class="color-white text-center pointer mt-5  px-3"
              >
                <img
                  class="img-fluid icon-menu"
                  src="/images/icons/impression.png"
                  alt=""
                />
                <div>
                  Obtenir des fichiers d'impression
                </div>
              </div> -->
              <div
                class="color-white text-center pointer mt-5  px-3"
                @click="downloadPresse"
              >
                <img
                  class="img-fluid icon-menu"
                  src="/images/icons/newspaper.png"
                  alt=""
                />
                <div>
                  Télécharger un exemple de communiqué de presse
                </div>
              </div>
            </div>
          </div>
        </transition>

        <div class="main-part" ref="mainpart">
          <router-view></router-view>
          <print-macaron-files-dialog
            v-model="printFilesMacaronDialog"
            :years="filesMacaron"
          ></print-macaron-files-dialog>
          <m-alert-dialog
            v-if="$store.state.alertDialog.container === 'app'"
            v-model="$store.state.alertDialog.value"
            :text="$store.state.alertDialog.text"
            container="app"
            :alertColor="$store.state.alertDialog.type"
            @close="$store.dispatch('showAlertDialog', false)"
          ></m-alert-dialog>
          <m-toast
            :toasts="$store.state.toasts"
            @close="
              timestamp => {
                $store.dispatch('showToast', timestamp);
              }
            "
          ></m-toast>
        </div>
      </div>
    </div>
    <div v-if="!$store.state.connected && $store.state.connectedCharged">
      <div class="bg-login d-lg-flex align-items-center">
        <login></login>
      </div>
    </div>
    <m-message-dialog
      v-model="$store.state.dialogErr"
      title="Erreur"
      :text="$store.state.dialogErrTxt"
      @close="$store.dispatch('showDialogError', false)"
    ></m-message-dialog>
    <!--     <user-win-edit
      v-model="userWinMe"
      :us_id="$store.state.user.us_id"
      :maFiche="true"
      :returnRoute="route"
      @close="userWinMe = false"
      @saved="meWinEditAction"
      @deleted="meWinEditAction"
      @canceled="meWinEditAction"
    >
    </user-win-edit>
    <preferencespersonal-win-edit
      v-model="preferenceEdit"
      :us_id="$store.state.user.us_id"
      @close="preferenceEdit = false"
      @saved="meWinEditAction"
      @canceled="meWinEditAction"
    ></preferencespersonal-win-edit> -->
  </div>
</template>

<script>
// import "@fullcalendar/core/main.css";
// import "@fullcalendar/daygrid/main.css";
// import "@fullcalendar/timegrid/main.css";
// import "@fullcalendar/list/main.css";
// import Vue from "vue";
// import OfferDetails from "./views/OffersDetails";
// Vue.component("offer-details", OfferDetails);

export default {
  name: "App",
  components: {},
  data() {
    // let year = this.$dayjs().format("YYYY");
    // if (
    //   this.$dayjs()
    //     .startOf("day")
    //     .isSameOrAfter(this.$dayjs(year + "-01-09"))
    // ) {
    //   year = parseInt(year) + 1;
    // }
    return {
      year: this.$store.state.currentyear,
      menuRoute: "",
      confirmLogoutDialog: false,
      showMenu: true,
      myaccountDropdown: false,
      userWinMe: false,
      preferenceEdit: false,
      route: this.$route.path,
      printFilesMacaronDialog: false,
      filesMacaron: []
    };
  },
  watch: {
    $route(to, from) {
      this.menuRoute = this.$route.meta.menu;
      if (this.menuRoute === "print") {
        this.showMenu = true;
        //this.hideOrShowMenu();
      }
      this.route = this.$route.path;
      /* if (to.path === "/profile/preferences/edit") {
        this.preferenceEdit = true;
      } */
    }
  },
  sockets: {
    connect() {
      this.$store.dispatch("socketjoinroom", this.$socket);
    }
  },
  computed: {
    usercandidat() {
      return this.$store.state.usercandidat;
    }
  },
  async mounted() {
    // console.log(
    //   "$store.state.userparticipation",
    //   this.$store.state.userparticipation
    // );
    // document.addEventListener(
    //   "touchmove",
    //   function(event) {
    //     console.log("event", event);
    //     if (event.scale !== 1) {
    //       event.preventDefault();
    //     }
    //   },
    //   false
    // );

    if (window.innerWidth < 992) this.showMenu = false;
    window.addEventListener("click", () => {
      this.myaccountDropdown = false;
    });
    // let tab = [
    //   { title: "coucou", text: "c'est moi", persist: true },
    //   { title: "coucou2", text: "c'est moi2", persist: true },
    //   { text: "au fait...", persist: true },
    //   { text: "au fait 2...", persist: true }
    // ];
    // for (let index = 0; index < tab.length; index++) {
    //   const el = tab[index];
    //   this.$store.dispatch("showToast", el);
    // }
    //this.user = this.$store.state.user;
  },
  methods: {
    getIsCandidatJure() {
      let txt = "";
      if (
        this.$store.state.userparticipation &&
        this.$store.state.userparticipation.pa_candidat
      )
        txt = "Candidat";
      if (
        this.$store.state.userparticipation &&
        this.$store.state.userparticipation.pa_jure
      )
        txt = "Juré";
      if (
        this.$store.state.userparticipation &&
        this.$store.state.userparticipation.pa_jure &&
        this.$store.state.userparticipation.pa_candidat
      )
        txt = "Candidat - juré";
      return txt;
    },
    hideOrShowMenu() {
      this.showMenu = !this.showMenu;
      //if (this.showMenu) this.$refs.mainpart.style.paddingLeft = `0`;
      /*       
      if (this.showMenu) {
        this.$refs.menu.style.transform = `translate(-250px, 0px)`;
      }
      if (!this.showMenu) {
        this.$refs.menu.style.transform = `translate(0px, 0px)`;
        this.$refs.mainpart.style.paddingLeft = `250px`;
      }
      this.showMenu = !this.showMenu; */
    },
    // resizeMainContainer() {
    //   if (!this.$refs.mainContainer) return;
    //   // if (this.$route.name == "login") {
    //   //   this.$refs.mainContainer.style.height = "100%";
    //   //   return;
    //   // }
    //   this.$refs.mainContainer.style.height =
    //     parseInt(window.innerHeight) - 64 + "px";
    // },
    // go(where) {
    //   this.$router.push(where);
    // },
    returnWeb() {
      window.open(`${this.$config.web_url}`, "_self");
    },
    logout() {
      this.confirmLogoutDialog = false;
      this.$store.commit("set_connexion", {
        accesstoken: null,
        refreshtoken: null,
        usercandidat: null,
        userparticipation: null,
        preferences: null
      });
      this.$router.push("/login").catch(err => {});
    },
    login() {
      this.$router.push("/login").catch(err => {});
    },
    preferenceWinEdit() {
      this.preferenceEdit = true;
      // this.$router.push("/profile/preferences/edit");
    },
    userWinEdit() {
      this.userWinMe = true;
      // this.$router.push("/profile/edit");
    },
    meWinEditAction() {
      // console.log("this.route", this.route);
      // this.$router.push(this.route);
      this.userWinMe = false;
      this.preferenceEdit = false;
    },
    saveWinUser() {
      // console.warn("this.$store.state.user.co_name", this.$store.state.user);
      // this.$refs.modal1.close();
    },
    openWinUser() {
      this.$refs.modal1.open();
    },
    closeWinUser() {
      this.$refs.modal1.close();
    },
    async printMacaronFiles() {
      let response = await this.$axios.get(
        this.$config.server_url + "/candidats/1.0/macaronsfiles"
      );
      this.filesMacaron = response.data.data;
      this.printFilesMacaronDialog = true;
    },
    async downloadHelp() {
      window.open(
        `${this.$config.server_url}/candidats/1.0/files/help?token=${this.$store.state.accesstoken}&origin=${this.$config.backoffice_url}`,
        "_blank"
      );
    },
    downloadCompletFiles() {
      /*       let haveFiles = false;
      let winesValide = this.$store.state.userparticipation.winesValide;
      if (winesValide && winesValide.length) {
        for (let iF = 0; iF < winesValide.length; iF++) {
          const row_wi = winesValide[iF];
          if (row_wi.wi_revendication && row_wi.wi_analyse && row_wi.in_id)
            haveFiles = true;
        }
      } */
      if (!this.$store.state.preferences.canDownloadFullFiles) {
        //return alert("Aucun fichier à télécharger");
        this.$store.dispatch("showDialogError", "Aucun fichier à télécharger");
        return;
      }

      window.open(
        `${this.$config.server_url}/candidats/1.0/fullfiles/${this.$store.state.userparticipation.lo_id}?token=${this.$store.state.accesstoken}&origin=${this.$config.backoffice_url}`,
        "_blank"
      );
    },
    downloadPresse() {
      window.open(
        `${this.$config.server_url}/commons/1.0/files/yp_file_communique_presse/${this.$store.state.currentyear}`,
        "_blank"
      );
    }
  }
};
</script>
<style lang="scss">
@import "./scss/styles.scss";

$heightTopBar: 60px;
$widthLeftMenu: 16.66667%;

// .container-logo {
//   background-color: #bcbcbc;
// }
.container-logo-app {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.top-bar {
  background-size: cover;
  background-position: center;
  background-color: #fff;
  height: $heightTopBar;
  .top-bar-right {
    flex-grow: 1;
  }
  .top-bar-left {
    width: $widthLeftMenu;
    background-color: $them0-color-topbarleft;
  }
  .icon-topbar {
    color: #fff;
  }
}
.separation-verticale {
  width: 1px;
  background-color: #bcbcbc;
}
.menu-profil {
  display: inline-table !important;
  top: 98% !important;
  right: 0 !important;
  left: auto !important;
  li {
    width: 100%;
  }
}

/************* partie gauche ***************/
.icon-menu {
  width: 58px;
  margin-bottom: 10px;
}
.menu {
  position: absolute;
  z-index: 1000;
  background-color: $them0-color-menu;
  width: 60%;
  height: calc(100vh - #{$heightTopBar});
  transition: all 0.2s linear;
  overflow: hidden;
  padding: 20px;
  overflow: auto;
}
@media (min-width: 992px) {
  .menu {
    position: relative;
    width: $widthLeftMenu;
  }
}
/* Les animations d'entrée (« enter ») et de sortie (« leave »)  */
/* peuvent utiliser différentes fonctions de durée et de temps.  */
// .slide-menu-enter-active,
// .slide-menu-leave-active {
//   // transition: all 0.2s linear;
// }
.slide-menu-enter-to,
.slide-menu-leave {
  width: $widthLeftMenu;
}
.slide-menu-enter,
.slide-menu-leave-to {
  width: 0;
}

/************ main-part **************/
.main-part {
  flex-grow: 1;
  background-color: #efefef;
  //transition: all 0.5s ease;
  height: calc(100vh - #{$heightTopBar});
  overflow: auto;
  width: 83.333333%;
  padding: 15px 15px;
}
.bg-login {
  background-image: url("/images/pexels-bruno-cantuaria.jpeg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100%;
}

@media (min-width: 992px) {
  .main-part {
    padding: 15px 30px;
  }
}
/************ footer **************/

.v-select {
  background-color: white;
}
</style>
